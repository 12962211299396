/* eslint-disable react/prop-types */
import React, { useState, useRef, useMemo, useImperativeHandle, forwardRef, useEffect, useCallback } from "react";
import { hit } from "api";
import querystring from "query-string";
import { debounce } from "lodash";
import { Box, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { InView } from "react-intersection-observer";
import clsx from "clsx";

const DEBOUNCING_TIME = 200;
const DEFAULT_LIMIT = 10;
const EmptyDefault = () => <Typography>EMPTY_SEARCH</Typography>;

const useStyles = makeStyles((theme) => ({
  horizontal: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  vertical: {
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  base: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    overflow: "auto",
  },
}));

const Paginator = (props, ref) => {
  const {
    endpoint,
    maxPage,
    horizontal,
    ListEmptyComponent,
    ListErrorComponent,
    ListLoadingComponent,
    ListWrapperComponent,
    ListFirstItemComponent,
    filters,
    renderItem,
    className,
    ...rest
  } = props;
  const isInitialRender = useRef(true);
  const totalPages = useRef(null);
  const isEnded = useRef(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState({
    error: false,
    wiping: false,
    loading: true,
    refreshing: false,
  });
  const limit = filters?.limit || DEFAULT_LIMIT;
  const classes = useStyles();
  const canConsume = useMemo(() => (
    !isEnded.current && (!totalPages.current || totalPages.current > page)
  ), [page]);

  const consume = useCallback(debounce(async (pg, wipe) => {
    const stringifiedQuery = querystring.stringify({
      limit,
      offset: (rest.numColumns || 1) * 10 * pg,
      ...filters,
    });
    
    const computedEndpoint = {
      ...endpoint,
      url: `${endpoint.url}?${stringifiedQuery}`,
    };
    
    setStatus({ loading: true, wiping: wipe });
    const { data: newData, error } = await hit(computedEndpoint);
    if (error) {
      setStatus({ error });
      return;
    }

    setPage(pg);
    totalPages.current = newData.totalCount / limit;
    isEnded.current = newData.isEnded || (newData.elements.length < limit);
    setData((d) => (wipe ? newData.elements : d.concat(newData.elements)));
  }, DEBOUNCING_TIME), [filters, limit]);

  const wipeAndFetch = () => consume(0, true);

  useImperativeHandle(ref, () => ({
    setData,
    refresh: wipeAndFetch,
  }));
  
  useEffect(() => {
    if (!isInitialRender.current) {
      setStatus({ loading: false });
    }
  }, [data]);
  
  useEffect(() => {
    wipeAndFetch();
  }, [filters]);

  useEffect(() => {
    isInitialRender.current = false;
  }, []);
  
  const nextPage = () => {
    if (!status.loading && canConsume) {
      if (maxPage && page + 1 >= maxPage) return;
      consume(page + 1);
    }
  };

  const EmptyComponent = ListEmptyComponent || EmptyDefault;
  const ErrorComponent = ListErrorComponent || EmptyComponent;
  const FinalEmptyComponent = status.error ? ErrorComponent : (!status.loading && EmptyComponent);

  return (
    <div className={clsx(horizontal && classes.container)}>
      <div
        className={clsx(
          classes.base,
          !horizontal && classes.vertical,
          horizontal && classes.horizontal,
          className,
        )}
        {...rest}
      >
        {
          ListFirstItemComponent && <ListFirstItemComponent />
        }
        {
          !status.wiping && (
            ListWrapperComponent
              ? (
                <ListWrapperComponent>
                  {data.map((item) => renderItem(item))}
                </ListWrapperComponent>
              )
              : data.map((item) => renderItem(item))
          )
        }
        {
          status.loading && ListLoadingComponent && [1, 2, 3].map(() => (
            <ListLoadingComponent />
          ))
        }
        {status.loading && !horizontal && <LinearProgress />}
        { !data.length && FinalEmptyComponent && <FinalEmptyComponent />}
        <InView
          threshold={0}
          initialInView
          onChange={(inView) => {
            if (inView) nextPage();
          }}
        >
          <Box width={1} height={1} />
        </InView>
      </div>
    </div>

  );
};

export default forwardRef(Paginator);
